import {
  useState,
  useEffect,
  useRef,
  ForwardedRef,
  MutableRefObject
} from 'react'

export const WhiteSpaceRegex = /^\s+$/

type Falsy = false | 0 | '' | null | undefined

export const EmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export const IsNullOrWhiteSpace = (
  string: string | null | undefined | (() => string)
): boolean => {
  if (typeof string === 'function') {
    const value = string()
    return IsNullOrWhiteSpace(value)
  }

  return (
    string === null ||
    string === undefined ||
    string.length === 0 ||
    WhiteSpaceRegex.test(string)
  )
}

export function useForwardedRef<T>(ref: ForwardedRef<T>): MutableRefObject<T> {
  const innerRef = useRef<T>(null)

  useEffect(() => {
    if (!ref) return
    if (typeof ref === 'function') {
      ref(innerRef.current)
    } else {
      ref.current = innerRef.current
    }
  })

  return innerRef as MutableRefObject<T>
}

/** Triggers click event on spacebar and/or enter key. Also runs `event.preventDefault()`.
 * @example onKeyDown={(event) => handleKeyboardAsClick(event, safeRef, () =>
                event.stopPropagation()
              )
            }
  */
export const handleKeyboardAsClick = <T extends HTMLElement = HTMLDivElement>(
  event: React.KeyboardEvent<T>,
  /** Ref to element that should be programatically clicked when it's focused and the
   * keyboard events happen
   */
  refToClick: React.RefObject<T>,
  /** Anything extra that needs to run on enter or spacebar press */
  callback?: () => void
) => {
  if (event.key === ' ' || event.key === 'Enter') {
    refToClick.current?.click()
    event.preventDefault()
    callback && callback()
  }
}

/**
 * Ensures no string versions of falsy values (e.g. "undefined", "null", "false") get added to an element's class list.
 * @example <Component className={safeClass`${styles.self}${passedClassNamePropThatMightBeUndefined}`} />
 * @example // Makes it unnecessary to have this structure
 * <Component className={`${possiblyNullishClassName ? possiblyNullishClassName : ''}`} />
 * @param strings
 * @param values e.g. styles.someClassName
 * @returns string
 */
export const safeClass = (
  strings: TemplateStringsArray,
  ...values: (Falsy | string)[]
) => {
  return (
    values.map((value, i) => `${strings[i]}${value || ''}`).join('') +
    // template literals always start and end with a string (even if the string is empty) so the strings array
    // is going to be one longer than the values array
    strings[strings.length - 1]
  )
}

import { FC, HTMLAttributes } from 'react'

import { IsNullOrWhiteSpace } from '../../../helpers'
import { IInputOurProps } from '..'
import { Column, Row } from '../../blocks'
import styles from './styles.module.scss'

/**
 * The common container for inputs that has the UI for `label`, `info`, `error`, and the `optional` indicator.
 *
 * Frame, as in, not the Core.
 */
export const InputFrame: FC<
  Omit<
    IInputOurProps & {
      className?: HTMLAttributes<HTMLElement>['className']
      innerClassName?: HTMLAttributes<HTMLElement>['className']
    },
    'icon'
  >
> = ({ className, label, info, error, optional, innerClassName, children }) => {
  return (
    <Column
      flex='unset'
      className={`${styles.self} ${
        IsNullOrWhiteSpace(error) ? '' : styles.error
      } ${className ? className : ''}`}
      data-testid='input-frame-parent'
    >
      <Row className={styles.row} flex='unset' forceRow>
        <span className={styles.label} data-testid='input-label'>
          {label && (typeof label === 'string' ? <span>{label}</span> : label)}
          {optional ? <span data-testid='optional-label'>(Optional)</span> : ''}
        </span>
        {error && !IsNullOrWhiteSpace(error) && (
          <span
            className={`${styles.label} ${styles.error}`}
            data-testid='error-message'
          >
            *{typeof error === 'string' ? error : error()}
          </span>
        )}
      </Row>
      <Row
        className={`${styles.inputRow} ${innerClassName ? innerClassName : ''}`}
        flex='unset'
        forceRow
      >
        {children}
      </Row>
      {info && typeof info === 'string' ? (
        <span className={styles.info} data-testid='info-label'>
          {info}
        </span>
      ) : (
        info
      )}
    </Column>
  )
}

InputFrame.displayName = 'InputFrame'

export default InputFrame

import { ForwardRefExoticComponent, RefAttributes, forwardRef } from 'react'

import { IsNullOrWhiteSpace } from '../../../helpers'
import { InputProps } from '..'
import { Box } from '../../blocks'
import InputFrame from '../inputFrame'
import styles from './styles.module.scss'

export const TextInput: ForwardRefExoticComponent<
  InputProps & RefAttributes<HTMLInputElement>
> = forwardRef(
  ({ label, info, error, icon, optional, className, ...props }, ref) => {
    return (
      <InputFrame label={label} info={info} error={error} optional={optional}>
        <input
          className={`${styles.input} ${className} ${
            IsNullOrWhiteSpace(error) ? '' : styles.error
          }`}
          data-testid='text-input'
          ref={ref}
          autoComplete='off'
          title={label ? (typeof label === 'string' ? label : '') : ''}
          required={!optional}
          {...props}
        />
        {icon && (
          <Box className={styles.icon} data-testid='icon-container'>
            {icon}
          </Box>
        )}
      </InputFrame>
    )
  }
)

TextInput.displayName = 'TextInput'

export default TextInput

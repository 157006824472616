import React, { FunctionComponent } from 'react'

const ReferralPartners: FunctionComponent = () => {
  return (
    <div>
      <div className='haaby-row' style={{ marginBottom: '2rem' }}>
        <div className='haaby-col'>
          <div>
            <span>Bill Yates</span>
          </div>
          <div>
            <span>4 Corners Home Inspections</span>
          </div>
          <div>
            <span>701.799.5503</span>
          </div>
          <div>
            <span>BillYates79@gmail.com</span>
          </div>
        </div>
        <div className='haaby-col'>
          <a
            href='https://www.4CornersMNND.weebly.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              style={{ width: '300px' }}
              alt='4 Corners'
              src='corners4.jpg'
            />
          </a>
        </div>
      </div>
      <div className='haaby-row'>
        <div className='haaby-col'>
          <div>
            <span>Zach Johnson</span>
          </div>
          <div>
            <span>American Family Insurance</span>
          </div>
          <div>
            <span>218.463.1065</span>
          </div>
          <div>
            <span>Zjohnson@AmFam.com</span>
          </div>
        </div>
        <div className='haaby-col'>
          <img
            style={{ width: '300px' }}
            src='zachjohnson.jpg'
            alt='Zach Johnson'
          />
        </div>
      </div>
    </div>
  )
}

export default ReferralPartners

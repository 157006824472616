import React, { FunctionComponent } from 'react';

const LoyaltyProgram: FunctionComponent = () => {
    return (
        <div>
            <h1 className="mb-5 mt-5">Haaby Real Estate Loyalty Program</h1>
            <p className="loyalty-text">
                I'm very excited to announce that Haaby Real Estate offers a loyalty program called Vanishing Commissions. For every home that you buy with Haaby Real Estate, your commission will be reduced by 1/2% when you sell a home with Haaby Real Estate! This is NOT a limited time offer. More good news; it's retroactive. If you've bought a home with Haaby Real Estate as far back as January 1st, 2014, your discounted commission will apply when you sell.
            </p>
            <p className="loyalty-text">
                Here’s an example of how it’ll work:
            </p>
            <p className="loyalty-text">
                Donald buys a home with Haaby Real Estate. When he decides that he needs a bigger home, he can sell his home with Haaby Real Estate for a commission rate of 5.0%. Now that Donald has upgraded and bought a second home with Haaby Real Estate, he’ll get to sell this one for 4.5% when he decides to move next time. This will continue until he’s bought enough homes to qualify for a commission rate of 3.0% commission. Are you getting it? We’re talking about savings thousands of dollars over your lifetime!
            </p>
            <p className="loyalty-text">
                Now there's no need to wonder if you're getting the best deal so you can stop wasting your time shopping around. Both Fargo-Moorhead and Roseau area clients will be able to benefit from the Vanishing Commissions program.
            </p>
            <p className="loyalty-text">
                *Vanishing Commissions is a progressive program. It uses a 5.5% commission as a starting point and it is capped at a reduction of 2 1/2% (that would be after you've bought 5 homes with Haaby Real Estate).
            </p>
            <p className="loyalty-text">
                **The benefits of Vanishing Commissions are reset if you buy or sell a home with another brokerage or on your own.
            </p>
            <p className="loyalty-text">
                ***Signed Representation Agreements are required to participate in the program.
            </p>
            <p className="loyalty-text">
                ****Haaby Real Estate reserves the right to change or end the program at any time. In that event, you will be locked into the commission rate that you were entitled to under the program. Resetting of benefits will still apply if you buy or sell a home with another brokerage or on your own.
            </p>
            <p className="loyalty-text">
                I look forward to building long-term relationships with all of my clients. Thank you to everyone for your continued support of Haaby Real Estate.
            </p>
        </div>
    )
}

export default LoyaltyProgram;
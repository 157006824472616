import { safeClass } from '../../../helpers'
import { ForwardRefExoticComponent, RefAttributes, forwardRef } from 'react'
import { IBoxProps } from '../box'
import styles from './styles.module.scss'

export interface IRowProps extends Omit<IBoxProps, 'flexDirection'> {
  forceRow?: boolean
  wrap?: boolean
}

export const Row: ForwardRefExoticComponent<
  IRowProps & RefAttributes<HTMLDivElement>
> = forwardRef(
  (
    { flex = 1, gap, children, className, style, forceRow, wrap, ...props },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={safeClass`${styles.row} ${className} ${
          forceRow && styles.forceRow
        } ${wrap && styles.wrap}`}
        style={{
          flex,
          gap,
          ...style
        }}
        {...props}
      >
        {children}
      </div>
    )
  }
)

Row.displayName = 'Row'

export default Row

import { Property } from 'csstype'
import { safeClass } from '../../../helpers'
import React, {
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef
} from 'react'
import styles from './styles.module.scss'

export interface IBoxProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  flex?: Property.Flex | number | 'unset'
  gap?: Property.Gap | number
  flexDirection?: Property.FlexDirection
}

export const Box: ForwardRefExoticComponent<
  IBoxProps & RefAttributes<HTMLDivElement>
> = forwardRef(
  (
    {
      flex = 'unset',
      gap,
      flexDirection = 'column',
      children,
      className,
      style,
      ...props
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={safeClass`${styles.box} ${className || ''}`}
        style={{
          flex,
          gap,
          flexDirection,
          ...style
        }}
        {...props}
      >
        {children}
      </div>
    )
  }
)

Box.displayName = 'Box'

export default Box

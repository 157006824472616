import React, { FunctionComponent } from 'react'

const AboutSteve: FunctionComponent = () => {
  return (
    <div>
      <div className='row about-steve py-5'>
        <div className='col-md-4'>
          <img
            className='about-us-steve-image'
            alt='Steve Haaby'
            src='Steve Haaby 5x7.jpg'
          />
        </div>
        <div className='col-md-8'>
          <h1 className='text-center pb-3'>- About Steve -</h1>
          <p className='about-steve-text'>
            Steve is the Broker/Owner for Haaby Real Estate. He’s been a Fargo,
            ND resident since 2005. He lives in South Fargo with his wife Kellie
            and their kids Savannah and Lincoln. They love the Fargo-Moorhead
            area and feel that it’s a great place to raise a family. When Steve
            isn’t showing homes, attending home inspections or closings, he
            spends most of his time chasing the kids around for sports, school
            and church activities. If he ever has “spare time”, he loves
            fishing, swimming, boating, campfires and listening to music.
          </p>
        </div>
        <div className='col-md-2'></div>
      </div>
    </div>
  )
}

export default AboutSteve

import React, { FunctionComponent } from 'react'

const AboutUs: FunctionComponent = () => {
  return (
    <div>
      <h1 className='mb-5 mt-5'>About Haaby Real Estate</h1>
      <p className='loyalty-text'>
        At Haaby Real Estate, we specialize in providing highly responsive
        service to every buyer and seller that we represent. We consider
        ourselves your real estate advisors. We walk through the home with you
        and help you avoid pitfalls that you might not see. We negotiate on your
        behalf. We help you mitigate risk. We do tons of work behind the scenes
        to ensure that your transaction goes smoothly all the way to closing. We
        look out for YOUR best interests even above our own because that’s the
        right thing to do. Would you like to work with the same person
        throughout the process? You got it!
      </p>
      <p className='loyalty-text'>
        Haaby Real Estate has been servicing the Fargo-Moorhead area since 2013.
        We know the market. Whether you’re looking for your first home,
        upsizing, downsizing, looking to sell or invest; we want to be part of
        that process. Let Haaby Real Estate be the company that you trust with
        all of your real estate needs.
      </p>
    </div>
  )
}

export default AboutUs

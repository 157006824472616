import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Standard from './components/layouts/standard'

import Listings from './pages/listings'
import Search from './pages/search'

import AboutUs from './pages/aboutUs'
import ReferralPartners from './pages/referralPartners'
import LoyaltyProgram from './pages/loyaltyProgram'
import AboutSteve from './pages/aboutSteve/aboutSteve'
import ContactUs from './pages/contactUs/contactUs'

function App() {
  return (
    <BrowserRouter>
      <Standard>
        <Routes>
          <Route path={'/'} element={<Listings />} />
          <Route path={'/listings'} element={<Listings />} />
          <Route path={'/property-search'} element={<Search />} />
          <Route path={'/about-us'} element={<AboutUs />} />
          <Route path={'/about-steve'} element={<AboutSteve />} />
          <Route path={'/referralpartners'} element={<ReferralPartners />} />
          <Route path={'/loyaltyprogram'} element={<LoyaltyProgram />} />
          <Route path={'/contactus'} element={<ContactUs />} />
        </Routes>
      </Standard>
    </BrowserRouter>
  )
}

export default App

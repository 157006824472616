'use client'

import {
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef,
  useEffect,
  useMemo,
  useState
} from 'react'

import { FaCheck } from '@react-icons/all-files/fa/FaCheck'
import { FaRegMinusSquare } from '@react-icons/all-files/fa/FaRegMinusSquare'

import {
  useForwardedRef,
  IsNullOrWhiteSpace,
  handleKeyboardAsClick
} from '../../../helpers'
import { InputProps } from '..'
import { Column, Row } from '../../blocks'
import styles from './styles.module.scss'

/** Matches aria-checked attribute */
type CheckedStateWithMixed = boolean | 'mixed'

interface CheckboxClickEvent extends React.MouseEvent<HTMLDivElement> {
  checked: boolean
}

export const Checkbox: ForwardRefExoticComponent<
  Omit<
    InputProps<HTMLDivElement>,
    'optional' | 'info' | 'onChange' | 'onClick'
  > & {
    onClick?: (event: CheckboxClickEvent) => void
    isIndeterminate?: boolean
    stopPropagation?: boolean
  } & RefAttributes<HTMLDivElement>
> = forwardRef(
  (
    {
      label,
      error,
      disabled,
      onClick,
      className,
      isIndeterminate = false,
      checked,
      stopPropagation = true,
      ...props
    },
    ref
  ) => {
    const safeRef = useForwardedRef(ref)
    const [checkedState, setCheckedState] =
      useState<CheckedStateWithMixed>(false)

    const labelString = useMemo(() => {
      return label ? (typeof label === 'string' ? label : '') : ''
    }, [label])

    const initialCheckedState = useMemo(() => {
      return isIndeterminate ? 'mixed' : checked ?? false
    }, [checked, isIndeterminate])

    useEffect(() => {
      if (isIndeterminate && checkedState !== 'mixed') {
        setCheckedState('mixed')
      }
    }, [checkedState, isIndeterminate])

    useEffect(() => {
      setCheckedState(initialCheckedState)
    }, [initialCheckedState])

    return (
      <Column
        className={`${styles.self}  ${
          IsNullOrWhiteSpace(error) ? '' : styles.error
        }`}
      >
        <Row
          flex='unset'
          forceRow
          className={`${styles.row} ${
            disabled ? styles.disabled : ''
          } ${className}`}
          onClick={() => safeRef.current?.click()}
          data-testid='checkbox'
          onKeyDown={(event) => {
            handleKeyboardAsClick(event, safeRef)
          }}
        >
          <div
            className={`${styles.input} ${
              checkedState ? styles.checkedOrMixed : ''
            } ${IsNullOrWhiteSpace(error) ? '' : styles.error}`}
            ref={safeRef}
            title={labelString}
            aria-checked={checkedState}
            aria-disabled={disabled}
            role='checkbox'
            type='checkbox'
            tabIndex={0}
            onClick={(event: CheckboxClickEvent) => {
              if (!disabled) {
                if (checkedState === false) {
                  const isChecked = true
                  setCheckedState(isChecked)
                  event.checked = isChecked
                  onClick && onClick(event)
                } else {
                  const isChecked = false
                  setCheckedState(isChecked)
                  event.checked = isChecked
                  onClick && onClick(event)
                }
                if (stopPropagation) {
                  event.stopPropagation()
                }
              }
            }}
            onKeyDown={(event) => {
              handleKeyboardAsClick(event, safeRef, () =>
                event.stopPropagation()
              )
            }}
            {...props}
          >
            {checkedState === true && <FaCheck />}
            {checkedState === 'mixed' && <FaRegMinusSquare />}
          </div>
          <Row className={styles.label} data-testid='input-label'>
            {label}
          </Row>
        </Row>
        {error && !IsNullOrWhiteSpace(error) && (
          <span className={`${styles.error}`} data-testid='error-message'>
            *{typeof error === 'string' ? error : error()}
          </span>
        )}
      </Column>
    )
  }
)

Checkbox.displayName = 'Checkbox'

export default Checkbox

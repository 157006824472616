import { ForwardRefExoticComponent, RefAttributes, forwardRef } from 'react'

import { IsNullOrWhiteSpace } from '../../../helpers'
import { InputProps } from '..'
import { Column } from '../../blocks'
import InputFrame from '../inputFrame'
import styles from './styles.module.scss'

export const MultiLineInput: ForwardRefExoticComponent<
  InputProps<HTMLTextAreaElement> & RefAttributes<HTMLTextAreaElement>
> = forwardRef(
  ({ label, info, error, icon, optional, className, ...props }, ref) => {
    const labelString = label ? (typeof label === 'string' ? label : '') : ''

    return (
      <Column className={styles.self}>
        <InputFrame label={label} info={info} error={error} optional={optional}>
          <textarea
            className={`${styles.input} ${className} ${
              !IsNullOrWhiteSpace(error) ? styles.error : ''
            }`}
            ref={ref}
            autoComplete='off'
            title={labelString}
            required={!optional}
            data-testid='multi-line-input'
            {...props}
          />
          {icon && icon}
        </InputFrame>
      </Column>
    )
  }
)

MultiLineInput.displayName = 'MultiLineInput'

export default MultiLineInput

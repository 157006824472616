import React, { FunctionComponent } from 'react'

const Search: FunctionComponent = () => {
  return (
    <div>
      <h1 className='mb-5 mt-5'>Property Search</h1>
      <iframe
        title='Property Search'
        src='https://link.flexmls.com/1trbx395xp9q,4'
        className='customframe'
      ></iframe>
    </div>
  )
}

export default Search

import {
  handleKeyboardAsClick,
  safeClass,
  useForwardedRef
} from '../../helpers'
import React, {
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef
} from 'react'
import styles from './styles.module.scss'

export interface IButtonProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  variant?: 'solid' | 'outline' | 'ghost' | 'link'
  disabled?: boolean
  loading?: boolean
  submit?: boolean
}

/**  
  # Button
  It's a button.  fun times.
  
  Notes:
  - When the submit property is set to true, a hidden input element is put on the page that can be used with
  a form element.  Most elements within the form element will treat the enter key as a submit.

  - When using the form element, use the noValidate property to turn off it's validating and use ours instead.
    ex.    <form noValidate></form>

  - Might also need a style on the form to get things to display properly again.
    .formElementFix {
       display: contents;
    }

  - There is a helper function to assist with the onSubmit, search for submitFormHelper for more info
  
*/
export const Button: ForwardRefExoticComponent<
  IButtonProps & RefAttributes<HTMLDivElement>
> = forwardRef(
  (
    {
      children,
      className,
      style,
      variant = 'solid',
      disabled,
      onClick,
      loading,
      submit = false,
      ...props
    },
    ref
  ) => {
    const safeRef = useForwardedRef(ref)
    return (
      <div
        ref={safeRef}
        role='button'
        tabIndex={0}
        className={safeClass`${styles.self} ${styles[variant]} ${
          className || ''
        } ${disabled || loading ? styles.disabled : ''}`}
        style={{
          ...style
        }}
        onKeyDown={(event) => {
          handleKeyboardAsClick(event, safeRef)
        }}
        data-testid='button'
        onClick={(event) => {
          if (disabled) {
            event.preventDefault()
          } else {
            !loading && onClick && onClick(event)
          }
        }}
        {...props}
      >
        {children}
        {submit && <input type='submit' className={styles.hidden}></input>}
      </div>
    )
  }
)

Button.displayName = 'Button'

export default Button

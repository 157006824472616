import React, { FunctionComponent } from 'react'

const Listings: FunctionComponent = () => {
  return (
    <div>
      <h1 className='mb-5 mt-5'>Our Listings</h1>
      <iframe
        src='//link.flexmls.com/1iva0mc1nu0i,4'
        className='customframe'
        title='Property Listings'
      ></iframe>
    </div>
  )
}

export default Listings

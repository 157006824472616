import React, { FunctionComponent, useState } from 'react'
import { Checkbox, MultiLineInput, TextInput } from '../../components/inputs'
import { Column, Row } from '../../components/blocks'
import Button from '../../components/button'
import emailjs from 'emailjs-com'
import { EmailRegex, IsNullOrWhiteSpace } from '../../helpers'

type IInput<T> = {
  value: T
  error: string
  info?: string | React.ReactNode
  required?: boolean
}

interface IContactState {
  firstName: IInput<string>
  lastName: IInput<string>
  email: IInput<string>
  phone: IInput<string>
  buying: IInput<boolean>
  selling: IInput<boolean>
  newConstruction: IInput<boolean>
  mortgage: IInput<boolean>
  insurance: IInput<boolean>
  investment: IInput<boolean>
  other: IInput<boolean>
  message: IInput<string>
}

const AboutUs: FunctionComponent = () => {
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [state, setState] = useState<IContactState>({
    firstName: {
      value: '',
      error: '',
      required: true
    },
    lastName: {
      value: '',
      error: '',
      required: true
    },
    email: {
      value: '',
      error: '',
      required: true
    },
    phone: {
      value: '',
      error: '',
      required: true
    },
    buying: {
      value: false,
      error: '',
      required: false
    },
    selling: {
      value: false,
      error: '',
      required: false
    },
    newConstruction: {
      value: false,
      error: '',
      required: false
    },
    mortgage: {
      value: false,
      error: '',
      required: false
    },
    insurance: {
      value: false,
      error: '',
      required: false
    },
    investment: {
      value: false,
      error: '',
      required: false
    },
    other: {
      value: false,
      error: '',
      required: false
    },
    message: {
      value: '',
      error: '',
      required: false
    }
  })

  const validate = () => {
    const stateCopy = { ...state }
    let valid = true

    if (IsNullOrWhiteSpace(stateCopy.email.value)) {
      valid = false
      stateCopy.email.error = 'Please enter an email address'
    }

    if (!EmailRegex.test(stateCopy.email.value)) {
      valid = false
      stateCopy.email.error = 'Please enter a valid email'
    }

    if (IsNullOrWhiteSpace(stateCopy.phone.value)) {
      valid = false
      stateCopy.phone.error = 'Please enter a phone number'
    }

    if (IsNullOrWhiteSpace(stateCopy.firstName.value)) {
      valid = false
      stateCopy.firstName.error = 'Please enter a first name'
    }

    if (IsNullOrWhiteSpace(stateCopy.lastName.value)) {
      valid = false
      stateCopy.lastName.error = 'Please enter a last name'
    }

    if (!valid) setState(stateCopy)

    return valid
  }

  const submit = () => {
    if (validate() && !submitted) {
      const emailData = {
        first_name: state.firstName.value,
        last_name: state.lastName.value,
        email: state.email.value,
        phone: state.phone.value,
        buying: state.buying.value ? 'Yes' : 'No',
        selling: state.selling.value ? 'Yes' : 'No',
        new_construction: state.newConstruction.value ? 'Yes' : 'No',
        mortgage: state.mortgage.value ? 'Yes' : 'No',
        insurance: state.insurance.value ? 'Yes' : 'No',
        investment: state.investment.value ? 'Yes' : 'No',
        other: state.other.value ? 'Yes' : 'No',
        message: state.message.value
      }

      emailjs
        .send(
          process.env.REACT_APP_EMAIL_SERVICE || '',
          process.env.REACT_APP_EMAIL_TEMPLATE || '',
          emailData,
          process.env.REACT_APP_EMAIL_USER || ''
        )
        .then(
          (result) => {
            console.log('Email successfully sent!', result.text)
            setSubmitted(true)
          },
          (error) => {
            console.log('Failed to send email.', error.text)
          }
        )
    }
  }

  return (
    <div className='contact-parent'>
      <h1 className='mb-5 mt-5'>Contact Haaby Real Estate</h1>
      <Column gap={'1rem'}>
        <TextInput
          disabled={loading || submitted}
          error={state.firstName.error}
          label='First Name'
          onChange={(event) => {
            setState({
              ...state,
              firstName: {
                ...state.firstName,
                value: event.target.value,
                error: ''
              }
            })
          }}
          value={state.firstName.value}
        />
        <TextInput
          disabled={loading || submitted}
          error={state.lastName.error}
          label='Last Name'
          onChange={(event) => {
            setState({
              ...state,
              lastName: {
                ...state.lastName,
                value: event.target.value,
                error: ''
              }
            })
          }}
          value={state.lastName.value}
        />
        <TextInput
          disabled={loading || submitted}
          error={state.email.error}
          label='Email'
          onChange={(event) => {
            setState({
              ...state,
              email: {
                ...state.email,
                value: event.target.value,
                error: ''
              }
            })
          }}
          value={state.email.value}
        />
        <TextInput
          disabled={loading || submitted}
          error={state.phone.error}
          label='Phone Number'
          onChange={(event) => {
            setState({
              ...state,
              phone: {
                ...state.phone,
                value: event.target.value,
                error: ''
              }
            })
          }}
          value={state.phone.value}
        />
        <div>
          <Row className='check-container'>
            <Checkbox
              checked={state.buying.value}
              label={'Buying'}
              onClick={() => {
                setState({
                  ...state,
                  buying: {
                    ...state.buying,
                    value: !state.buying.value,
                    error: ''
                  }
                })
              }}
            />
            <Checkbox
              checked={state.selling.value}
              label={'Selling'}
              onClick={() => {
                setState({
                  ...state,
                  selling: {
                    ...state.selling,
                    value: !state.selling.value,
                    error: ''
                  }
                })
              }}
            />
            <Checkbox
              checked={state.newConstruction.value}
              label={'New Construction'}
              onClick={() => {
                setState({
                  ...state,
                  newConstruction: {
                    ...state.newConstruction,
                    value: !state.newConstruction.value,
                    error: ''
                  }
                })
              }}
            />
          </Row>
          <Row>
            <Checkbox
              checked={state.mortgage.value}
              label={'Mortgage'}
              onClick={() => {
                setState({
                  ...state,
                  mortgage: {
                    ...state.mortgage,
                    value: !state.mortgage.value,
                    error: ''
                  }
                })
              }}
            />
            <Checkbox
              checked={state.insurance.value}
              label={'Insurance'}
              onClick={() => {
                setState({
                  ...state,
                  insurance: {
                    ...state.insurance,
                    value: !state.insurance.value,
                    error: ''
                  }
                })
              }}
            />
            <Checkbox
              checked={state.investment.value}
              label={'Investment'}
              onClick={() => {
                setState({
                  ...state,
                  investment: {
                    ...state.investment,
                    value: !state.investment.value,
                    error: ''
                  }
                })
              }}
            />
            <Checkbox
              checked={state.other.value}
              label={'Other'}
              onClick={() => {
                setState({
                  ...state,
                  other: {
                    ...state.other,
                    value: !state.other.value,
                    error: ''
                  }
                })
              }}
            />
          </Row>
        </div>
        <MultiLineInput
          disabled={loading || submitted}
          error={state.message.error}
          label='Message'
          style={{
            height: 150,
            width: '100%',
            fontSize: 16
          }}
          onChange={(event) => {
            setState({
              ...state,
              message: {
                ...state.message,
                value: event.target.value,
                error: ''
              }
            })
          }}
          value={state.message.value}
        />
        <Button
          style={{
            flex: 1,
            backgroundColor: '#274294',
            padding: 5,
            alignSelf: 'center'
          }}
          onClick={submit}
          submit
          variant='solid'
        >
          SUBMIT
        </Button>
        {submitted && (
          <Row>
            <span>Thank you for submitting a contact request.</span>
          </Row>
        )}
      </Column>
    </div>
  )
}

export default AboutUs

import React, { FunctionComponent, ReactNode } from 'react'
import Drift from 'react-driftjs'
import useWindowDimensions from '../../../helpers'

interface IStandardProps {
  children: ReactNode
}

const Standard: FunctionComponent<IStandardProps> = ({ children }) => {
  let { width } = useWindowDimensions()
  const [expand, setExpand] = React.useState<boolean>(false)

  if (width < 768) {
    return (
      <div className='layouts_standard parent'>
        <div className='layouts_standard wrapper'>
          <div className='layouts_standard mobile'>
            <nav className='navbar navbar-expand-lg navbar-dark bg-light mobile-navbar'>
              <button className='navbar-toggler' type='button'>
                <span
                  className='navbar-toggler-icon'
                  onClick={() => {
                    setExpand(!expand)
                  }}
                ></span>
              </button>

              <div className={`nav-elements ${expand && 'active'}`} id=''>
                <ul className='navbar-nav mr-auto ml-auto'>
                  <li className='nav-item'>
                    <a className='nav-link' href='/'>
                      Our Listings
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      href='http://www.FMOpenHouses.com'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Open Houses
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='property-search'>
                      Property Search
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='about-us'>
                      About Haaby Real Estate
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='about-steve'>
                      About Steve
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='referralpartners'>
                      Referral Partners
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='loyaltyprogram'>
                      Loyalty Program
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='contactus'>
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </nav>

            <div className='logo-container' style={{ flex: 3 }}>
              <img
                className='logo'
                alt='Haaby Real Estate'
                src='haabylogo.jpeg'
              />
            </div>
          </div>

          <div className='layouts_standard overflow'>{children}</div>
          <div className='footer'>
            <div className='footer-links'>
              <a href='/'>Fargo Listings</a>
              <a href='roseau-area'>Roseau Listings</a>
              <a href='about-us'>About Us</a>
              <a href='referralpartners'>Referral Partners</a>
              <a href='loyaltyprogram'>Loyalty Program</a>
              <a href='contactus'>Contact Us</a>
            </div>
            <img
              className='image'
              alt='Habby Real Estate Postcard'
              src='webFooter.jpg'
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='layouts_standard parent'>
      <div className='layouts_standard wrapper'>
        <div className='layouts_standard header'>
          <div className='haaby_header'>
            <div className='image-container' style={{ flex: 1 }}>
              <img className='image' alt='Steve Haaby' src='steve.jpg' />
              <span className='name'>Steve Haaby</span>
              <span className='title'>Broker</span>
              <span className='title'>701.306.7401</span>
              <span className='title'>SteveHaaby@HaabyRealEstate.com</span>
            </div>
            <div className='logo-container' style={{ flex: 3 }}>
              <img
                className='logo'
                alt='Haaby Real Estate'
                src='haabylogo.jpeg'
              />
            </div>
          </div>
          <div className='haaby_nav'>
            <a href='/'>Our Listings</a>
            <a
              href='http://www.FMOpenHouses.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              Open Houses
            </a>
            <a href='property-search'>Property Search</a>
            <div className='dropdown-container'>
              <button className='dropdown-button'>
                About Us
                <i
                  className='fa fa-caret-down'
                  style={{ paddingLeft: '.25rem' }}
                ></i>
              </button>
              <div className='dropdown-content'>
                <a href='about-us'>About Haaby Real Estate</a>
                <a href='about-steve'>About Steve</a>
              </div>
            </div>
            <a href='referralpartners'>Referral Partners</a>
            <a href='loyaltyprogram'>Loyalty Program</a>
            <a href='contactus'>Contact Us</a>
          </div>
        </div>
        <div className='layouts_standard overflow'>{children}</div>
        <div className='footer'>
          <div className='col'>
            <div className='footer-links'>
              <a href='/'>Our Listings</a>
              <a href='search'>Property Search</a>
              <a href='about-us'>About Us</a>
              <a href='referralpartners'>Referral Partners</a>
              <a href='loyaltyprogram'>Loyalty Program</a>
              <a href='contactus'>Contact Us</a>
            </div>
          </div>
          <div className='col'>
            <img
              className='image'
              alt='Haaby Real Estate Postcard'
              src='webFooter.jpg'
            />
          </div>
        </div>
      </div>
      <Drift appId='8phbr27336hu' />
    </div>
  )
}

export default Standard

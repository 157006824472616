import { safeClass } from '../../../helpers'
import { ForwardRefExoticComponent, RefAttributes, forwardRef } from 'react'
import { IBoxProps } from '../box'
import styles from './styles.module.scss'

interface IColumnProps extends Omit<IBoxProps, 'flexDirection'> {}

export const Column: ForwardRefExoticComponent<
  IColumnProps & RefAttributes<HTMLDivElement>
> = forwardRef(
  ({ flex = 1, gap, children, className, style, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={safeClass`${styles.column} ${className}`}
        style={{
          flex,
          gap,
          ...style
        }}
        {...props}
      >
        {children}
      </div>
    )
  }
)

Column.displayName = 'Column'

export default Column
